import React, {useState, useEffect} from 'react'
import Layout from '../components/layout'

import MinutesEvents from '../components/Minutes/MinutesEvents'
import SEO from '../components/seo'
import Login from '../pages/login'
import '../assets/main.css';



const Minutes = ({ location }) => {
 

  const [agreed, setAgreed] = useState(false)

  // This runs when the page is loaded.
  useEffect(() => {
    if (localStorage.getItem('isAuthenticated')) {
      setAgreed(true)
    }
  }, [])

  return (
    <Layout location={location} crumbLabel="Minutes">
      <SEO title="Minutes" />{' '}
      {!agreed ? (
        <>
          <Login loginModal={agreed} />{' '}
          <p className="oMember"> Only members can view this page </p>{' '}
        </>
      ) : (
        <div className="box ">
          <MinutesEvents />
        </div>
      )}
    </Layout>
  )
}

export default Minutes
